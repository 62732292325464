<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTARKAN MAHASISWA</p>
        </header>
        <div class="card-content events-card">
          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Angkatan</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ stase.angkatan }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail has-text-weight-semibold is-4">Matakuliah</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ stase.mata_kuliah.nama }}</div>
          </div>
          <div class="columns is-mobile">
            <div class="column detail is-4 has-text-weight-semibold">Periode</div>
            <div class="column detail has-text-weight-semibold is-1">:</div>
            <div class="column detail has-text-left">{{ stase.mulai }} - {{ stase.hingga }}</div>
          </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">
                Penanggung Jawab
              </div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <template v-if="stase.preceptor">{{
                  stase.preceptor.nama
                }}</template>
              </div>
            </div>

          <!-- <table class="custom-header table is-fullwidth is-striped is-hoverable is-scrollable">
            <tr class="has-background-white-bis has-text-weight-semibold">
              <th class="ppds-header">PPDS Terdaftar</th>
            </tr>
            <tr>
              <td>
                <b-field class="ppds-field" grouped group-multiline>
                  <div v-for="pspd in stase.residen_set" :key="pspd.id" class="tag-control">
                    <b-tag class="is-grey-res">{{ pspd.nama }}</b-tag>
                  </div>
                </b-field>
                <div
                  v-if="stase.residen_set.length == 0"
                  class="content has-text-grey has-text-centered"
                >
                  <p>Belum ada PPDS yang terdaftar.</p>
                </div>
              </td>
            </tr>
          </table>-->
          <b-field
            label="Mahasiswa"
            custom-class="is-small"
            :type="{'is-danger': errorMap.stasepspd_set}"
            :message="errorMap.stasepspd_set"
          >
            <b-taginput
              v-model="stase.stasepspd_set"
              :data="pspdTags"
              autocomplete
              field="nama"
              icon="tag"
              type="is-info"
              @typing="getFilteredPSPDTags"
              @input="validate()"
            ></b-taginput>
          </b-field>

          <b-field
            label="Kelompok"
            custom-class="is-small"
            :type="{'is-danger': errorMap.kelompok_set}"
            :message="errorMap.kelompok_set"
          >
            <b-taginput
              v-model="stase.kelompok_set"
              @remove="onRemove"
              @add="onAdd"
              :data="kelompokTags"
              autocomplete
              field="nama"
              icon="tag"
              type="is-dark"
              @typing="getFilteredKelompokTags"
              @input="validate()"
            ></b-taginput>
          </b-field>
          <b-field
            v-for="kel in kelompok_pspd_map"
            :key="kel.id"
            :label="kel.nama"
            custom-class="is-small"
          >
            <b-taginput
            v-model="kel.pspdList"
              autocomplete
              :allow-new="false"
              :readonly="true"
              field="nama"
              icon="tag"
              type="is-info"
            ></b-taginput>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import StasePSPD from "../models/stasePSPD.js";

export default {
  name: "StasePSPDBatchCreate",
  mixins: [createUpdateMixin],
  data() {
    this.staseMdl = new StasePSPD();
    this.objectMdl = this.staseMdl; // alias yg digunakan di cuMixin
    let obv = this.staseMdl.getObservables();
    obv.pspdTags = [];
    obv.pspdList = [];
    obv.kelompokTags = [];
    obv.kelompokList = [];
    obv.kelompok_pspd_map = [];
    return obv;
  },
  computed: {
    isCreate() {
      // selalu true, karena tidak bergantung pada id
      // diperlukan karena akan digunakan sebagai saveContext
      return true;
    }
  },
  methods: {
    validate() {
      this.objectMdl.validate();
    },
    getFilteredPSPDTags(text) {
      this.pspdTags = this.pspdList.filter(option => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    getFilteredKelompokTags(text) {
      this.kelompokTags = this.kelompokList.filter(option => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    onAdd(kelompok) {
      const ppdsURL = `${APP_CONFIG.baseAPIURL}/anggota/mahasiswa/options/`;
      const params = { tag: kelompok.id, stase: this.stase.id };
      axios.get(ppdsURL, { params: params }).then(response => {
        // this.kelompok_pspd_map[kelompok.id] = {
        //   nama: kelompok.nama,
        //   pspdList: response.data.map(obj => obj.nama)
        // };
        this.kelompok_pspd_map.push({
          id: kelompok.id,
          nama: kelompok.nama,
          pspdList: response.data //.map(obj => obj.nama)
        });
      });
    },
    onRemove(kelompok) {
      let dataKelSet = this.kelompok_pspd_map.filter( kel => kel.id == kelompok.id);
      if (dataKelSet.length == 0) return;
      const idx = this.kelompok_pspd_map.indexOf(dataKelSet[0]);
      this.kelompok_pspd_map.splice(idx, 1);
    },
    fetchTagData() {
      const ppdsURL = `${APP_CONFIG.baseAPIURL}/anggota/mahasiswa/options/`;
      const params = { stase: this.stase.id };
      axios
        .get(ppdsURL, { params: params })
        .then(response => {
          this.pspdList = response.data;
        })
        .catch(() => {
          this.pspdList = [];
        });

      const kelURL = `${APP_CONFIG.baseAPIURL}/anggota/tag/options/`;
      axios
        .get(kelURL)
        .then(response => {
          this.kelompokList = response.data;
        })
        .catch(() => {
          this.kelompokList = [];
        });
    },
    fetchData() {
      this.objectMdl.load(this.$route.params.id, this.fetchTagData);
    },
    saveObject() {
      let postURL = `${APP_CONFIG.baseAPIURL}/stase/${this.stase.id}/mahasiswa/`;
      this.objectMdl.setApiURL(postURL);
      this.objectMdl.save(this.saveContext, () => {
        this.$router.go(-1);
      });
    }
  },
  watch: {
    stase: {
      // stase berubah, update edited
      handler(newValue, oldValue) {
        if (!this.staseMdl.getEdited()) {
          this.setEdited(!!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>


<style scoped lang="scss">
.ppds-header {
  padding-right: unset !important;
}

.ppds-field {
  margin-bottom: unset !important;
}

.is-grey-res {
  color: white !important;
  background-color: #6d6d6d !important;
}
</style>
